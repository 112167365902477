<template>
    <b-sidebar backdrop-variant="dark"
               backdrop
               right
               lazy
               shadow
               :visible="isOpen"
               @hidden="onHidden"
               width="320px">
        <template v-slot:title>
            <slot name="header">
                Kopiera från mall
            </slot>
        </template>
        <Loader v-if="isLoading" />
        <div class="px-2" v-else>
            <div class="card-body">
                <b-form @submit.prevent="onSubmit(false)">
                    <b-form-group label="Organisation"
                                  label-for="select-organization">
                        <b-form-select id="select-organization"
                                       text-field="name"
                                       value-field="id"
                                       v-model="selectedOrganizationId"
                                       :options="mergedOrganizationOptions"
                                       :disabled="isSubmitting"
                                       @change="onOrganizationChange"
                                       required></b-form-select>
                    </b-form-group>
                    <b-form-group label="Mall"
                                  label-for="customer-template">
                        <b-form-select id="customer-template"
                                       text-field="name"
                                       value-field="id"
                                       v-model="selected"
                                       :options="mergedOptions"
                                       :disabled="isSubmitting || !selectedOrganizationId"
                                       required></b-form-select>
                    </b-form-group>
                    <b-alert :show="validationByKey('Other').length > 0"
                             variant="danger">
                        <ul class="text-danger mb-0">
                            <!--<li :show="!isValidSelection">
                                Du måste välja en mall
                            </li>-->
                            <li v-for="message in validationByKey('Other')"
                                :key="message">
                                {{ message}}
                            </li>
                        </ul>
                    </b-alert>

                    <b-alert :show="successMessage.length > 0"
                             variant="success"
                             class="mb-0 mt-4">
                        <h6 class="mb-0 text-center">
                            <font-awesome-icon icon="info-circle" /> {{ successMessage }}
                        </h6>
                    </b-alert>

                    <b-row align-content="between" class="mt-3">
                        <b-col cols="12">
                            <b-alert :show="isSubmitting"
                                     variant="info"
                                     class="mb-0 mb-3">
                                <h6 class="mb-0 text-center">
                                    <font-awesome-icon icon="info-circle" /> Observera att kopieringen kan ta några minuter
                                </h6>
                            </b-alert>
                            <b-button v-b-tooltip.hover
                                      block
                                      variant="primary"
                                      :disabled="isSubmitting || !isValidSelection"
                                      @click="confirm"
                                      :title="isSubmitting ? 'Skapar...' : ''">
                                <font-awesome-icon v-if="isSubmitting"
                                                   icon="spinner"
                                                   spin />
                                {{isSubmitting ? 'Skapar...' : 'Skapa'}}
                            </b-button>
                        </b-col>
                    </b-row>
                </b-form>
            </div>
        </div>
    </b-sidebar>
</template>

<script>
    import { mapState } from 'vuex';
    import { post, get } from '@/helpers/api';
    import Loader from '@/components/Loader';

    export default {
        name: 'SaveBuildingPartPosition',
        components: {
            Loader,
        },
        props: {
            isOpen: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                isLoading: true,
                allOrganizations: [],
                selectedOrganizationId: null,
                selected: null,
                templateCustomers: [],
                isSubmitting: false,
                successMessage: '',
                validationErrors: null
            };
        },
        computed: {
            ...mapState({
                organizationId: (state) => state.user.user?.organizationId ?? null,
            }),
            mergedOptions() {
                return [{ id: null, name: (!this.selectedOrganizationId ? "Ange först organisation" : "---Välj---"), disabled: true }, ...this.templateCustomers];
            },
            mergedOrganizationOptions() {
                return [{ id: null, name: "---Välj---", disabled: true }, ...this.allOrganizations];
            },
            isValidOrganization() {
                return this.selected !== null;
            },
            isValidSelection() {
                return this.selected !== null;
            },
        },
        watch: {
            async isOpen(newValue, oldValue) {
                if (newValue) {
                    await get('Organization', `GetAll`).then((response) => {
                        this.allOrganizations = response.data;
                        this.isLoading = false;
                    });
                }
            }
        },
        methods: {
            async onOrganizationChange() {
                this.selected = null;
                await get('Customer', `GetAllTemplatesByOrganizationId/${this.selectedOrganizationId}`).then((response) => {
                    this.templateCustomers = response.data;
                    this.isLoading = false;
                });
            },
            async confirm(message) {
                this.$bvModal
                    .msgBoxConfirm('Är du säker?', {
                        title: 'Är du säker?',
                        size: 'sm',
                        cancelVariant: 'danger',
                        cancelTitle: 'Nej',
                        okTitle: 'Ja',
                        okVariant: 'primary',
                        footerClass: 'p-2 justify-content-between',
                        hideHeaderClose: false,
                        centered: true,
                        hideHeader: true
                    })
                    .then(async (confirmed) => {
                        if (confirmed)
                            await this.onSubmit();
                    });
            },
            async onSubmit() {
                this.resetsForm();
                this.isSubmitting = true;

                try {
                    await post('Copy', `CopyCustomerTemplateToOrganization/${this.organizationId}?templateId=${this.selected}`)
                        .then((x) => {
                            this.successMessage = `Kopierat ny kund ${x.data.entityId}`;

                            this.resetsForm();
                            this.close();
                        });
                } catch (ex) {
                    this.validationErrors = ex.response.data.errors;
                    this.isSubmitting = false;
                }
            },
            onHidden() {
                this.resetsForm();
                this.close();
            },
            close() {
                this.selectedOrganizationId = null;
                this.selected = null;
                this.$emit('close');
            },
            resetsForm() {
                this.successMessage = '';
                this.validationErrors = null;
                this.isSubmitting = false;
            },
            validationByKey(code) {
                return this.validationErrors ? this.validationErrors[code] || [] : [];
            },
        },
    };
</script>

<style scoped>
    .form-group {
        margin-bottom: 0.5rem;
    }
</style>
