<template>
  <div>
    <h1 v-if="!id">{{ form.id ? 'Ändra' : 'Skapa' }} kund</h1>
    <hr />
    <div>
      <b-tabs v-model="activeTab" content-class="mt-3">
        <b-tab title="Grunddata">
            <b-form @submit.prevent="onSubmit">
                <b-form-group id="input-group-customerNo"
                              label="Kundnr"
                              label-for="input-customerNo">
                    <b-form-input id="input-customerNo"
                                  v-model="form.customerNo"
                                  type="text"
                                  placeholder="Ange kundnr"
                                  :disabled="isSubmitting"
                                  autocomplete="off"
                                  aria-describedby="input-customerNo-help-block"
                                  required />
                    <b-form-text id="input-customerNo-help-block">
                        <ul class="text-danger mb-0">
                            <li v-for="message in validationByKey('CustomerNo')"
                                :key="message">
                                {{ message }}
                            </li>
                        </ul>
                    </b-form-text>
                </b-form-group>

                <b-form-group id="input-group-name"
                              label="Namn"
                              label-for="input-name">
                    <b-form-input id="input-name"
                                  v-model="form.name"
                                  type="text"
                                  placeholder="Ange namn"
                                  :disabled="isSubmitting"
                                  autocomplete="off"
                                  aria-describedby="input-name-help-block"
                                  required />
                    <b-form-text id="input-name-help-block">
                        <ul class="text-danger mb-0">
                            <li v-for="message in validationByKey('Name')" :key="message">
                                {{ message }}
                            </li>
                        </ul>
                    </b-form-text>
                </b-form-group>

            

                <b-form-group id="input-group-customerType" label="Typ" label-for="input-customerType">
                    <b-form-select id="input-customerType"
                                   v-model="form.customerType"
                                   :options="customerTypes"
                                   :disabled="isSubmitting"
                                   aria-describedby="input-customerType-help-block"
                                   required />
                    <b-form-text id="input-customerType-help-block">
                        <ul class="text-danger mb-0">
                            <li v-for="message in validationByKey('CustomerType')" :key="message">
                                {{ message }}
                            </li>
                        </ul>
                    </b-form-text>
                </b-form-group>


                <b-alert :show="validationByKey('Other').length > 0"
                         variant="danger">
                    <ul class="text-danger mb-0">
                        <li v-for="message in validationByKey('Other')" :key="message">
                            {{ message }}
                        </li>
                    </ul>
                </b-alert>

                <b-alert :show="successMessage.length > 0"
                         variant="success"
                         class="mb-0 mt-4">
                    <h6 class="mb-0 text-center">
                        <font-awesome-icon icon="info-circle" /> {{ successMessage }}
                    </h6>
                </b-alert>

                <b-button v-b-tooltip.hover
                          variant="primary"
                          type="submit"
                          class="float-right mt-4"
                          :class="{ 'btn-block': id }"
                          :disabled="isSubmitting"
                          :title="isSubmitting ? 'Sparar...' : ''">
                    <font-awesome-icon v-if="isSubmitting" icon="spinner" spin />
                    Spara
                </b-button>
            </b-form>
        </b-tab>
        <b-tab v-if="isEditMode" title="Behörighet" lazy>
          <Permissions :customer-id="form.id" />
        </b-tab>
      </b-tabs>
    </div>
  </div>
</template>

<script>
import { get, post } from '@/helpers/api';
import Permissions from '@/components/customer/Permissions';

export default {
  name: 'SaveCustomer',
  components: {
    Permissions
  },
  props: {
    id: String
  },
  data() {
    return {
      activeTab: 0,
      isSubmitting: false,
      successMessage: '',
      validationErrors: null,
      form: {
        id: null,
        customerNo: '',
        name: '',
        customerType: 0
        },
        customerTypes: [
            { value: 0, text: 'Kund' },
            { value: 1, text: 'Demo' },
            { value: 2, text: 'Mall' },
            
        ]
    };
  },
  computed: {
    isEditMode() {
      return this.form.id > 0;
    }
  },
  async mounted() {
    // Get customer if necessary.
    this.form.id = typeof this.id !== 'undefined' ? this.id : 0;

    if (this.isEditMode) {
      await get('Customer', `Get/${this.form.id}`)
        .then((x) => {
          this.form = x.data;
        })
        .catch((x) => {
          this.validationErrors = x.response.data.errors;
        });
    }
  },
  methods: {
    async onSubmit() {
      // Resets.
      this.successMessage = '';
      this.validationErrors = null;
      this.isSubmitting = true;
      // Post.
      await post('Customer', `Save/${this.form.id}`, this.form)
        .then((x) => {
          this.form.id = x.data.entityId;
          this.successMessage = `Sparade ${this.form.customerNo} ${this.form.name}`;
          this.validationErrors = x.data.errors;
        })
        .catch((x) => {
          this.validationErrors = x.response.data.errors;
        });
      this.isSubmitting = false;

      // On created.
      if (!this.validationErrors && this.form.id) this.onCreated();
    },
    onCreated() {
      this.hasSavedSuccessfullly = true;
    },
    validationByKey(code) {
      return this.validationErrors ? this.validationErrors[code] || [] : [];
    },
    resetForm() {
      this.form = {
        id: null,
        customerNo: '',
        name: '',
        customerType: 0
      };
      this.validationErrors = null;
    }
  }
};
</script>

<style scoped>
.form-group {
  margin-bottom: 0.5rem;
}
</style>
